:root {
  --primary-color: #FF6037;
  --secondary-color: #DDEEEF;
  --background-color: #FFFDF5;
  --black-color: #333;
  --dark-grey-color: #454545;
  --mid-grey-color: #5D5D5D;
  --light-grey-color: #888888;
  --border-light-grey-color: #D1D1D1;

  /* Fonts */
  --primary-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  /*== Elevation ==*/
  --shadow-depth-1: 0px 2px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.25);
  --shadow-depth-2: 0px 0px 2px 0px rgba(0, 0, 0, 0.20), 0px 2px 8px 0px rgba(0, 0, 0, 0.10);
  --shadow-depth-3: 0px 0px 3px 0px rgba(0, 0, 0, 0.10), 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
}

* {
  font-family: var(--primary-font-family);
  color: var(--black-color);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
