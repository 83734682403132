/* .gm-style > iframe + div {
    border: none !important;
    opacity: 0 !important;
} */

.gm-style .gm-style-iw-d {
    overflow: auto !important;
}

.gm-style .gm-style-iw-c {
    padding: 0 !important;
    border-radius: 12px !important;
    top: -36px !important;
}

.gm-style .gm-style-iw-tc {
    display: none !important;
}

.gm-ui-hover-effect {
    top: 5px !important;
    right: 5px !important;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.95) !important;
    height: 28px !important;
    width: 28px !important;
}

.gm-ui-hover-effect>span {
    margin: 7px !important;
}

.bv_court-item-popup .bv_court-item-contents h3,
.bv_court-item-popup .bv_court-item-contents p {
    padding: 0 12px;
}

.bv_court-item-popup .bv_court-item-contents h3 {
    margin: 8px 0 8px;
}

.bv_court-item-popup .bv_court-item-contents p {
    margin: 8px 0 12px;
}

.poi-info-window {
    padding: 8px 12px;
}